// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
//
import $ from 'jquery';

import '../../stylesheets/zeirishi_takais/application'
import '../../javascripts/zeirishi_takais/application'

import '../../images/zeirishi_takais/logo.svg'
import '../../images/zeirishi_takais/kv-pc.png'
import '../../images/zeirishi_takais/kv-tablet.png'
import '../../images/zeirishi_takais/kv-sp.png'
import '../../images/zeirishi_takais/kv-bg.png'
import '../../images/zeirishi_takais/kv-pic.png'

import '../../images/zeirishi_takais/text_number_3.svg'
import '../../images/zeirishi_takais/text_number_2.svg'
import '../../images/zeirishi_takais/text_number_1.svg'
import '../../images/zeirishi_takais/staff.svg'
import '../../images/zeirishi_takais/graph.svg'
import '../../images/zeirishi_takais/support.svg'
import '../../images/zeirishi_takais/blue.png'
import '../../images/zeirishi_takais/tsuusho_kaigo.png'
import '../../images/zeirishi_takais/houkago.png'
import '../../images/zeirishi_takais/shurou_shien.png'
import '../../images/zeirishi_takais/houmon_kaigo.png'
import '../../images/zeirishi_takais/kango.png'
import '../../images/zeirishi_takais/section_footer.png'
